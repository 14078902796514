import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'
import RegisterNowButton from 'src/components/Button/RegisterNowButton'
import RedLine from 'src/components/RedLine'
import SectionTitle from 'src/components/SectionTitle'
import AgendaLineItem from 'src/components/AgendaLineItem'
import Banner from 'src/components/Banner'
import Popup from 'reactjs-popup'
import SliderForPopup from 'src/components/SliderForPopup'
import close_btn_white from 'src/images/prumdrt2020/close_btn_white.svg'
import { Cookies } from 'react-cookie'
import 'src/styles/home.scss'
import 'src/styles/global.scss'
import moment from 'moment-timezone'
import _ from 'lodash'
import VideoHomePage from 'src/components/VideoHomePage'
import video_section_image from 'src/images/prumdrt2020/video_section_image.jpg'

import popup_3 from 'src/images/prumdrt2020/frontend_images/popup_messages/popup_3.jpg'
import popup_4 from 'src/images/prumdrt2020/frontend_images/popup_messages/popup_4.jpg'
import popup_5 from 'src/images/prumdrt2020/frontend_images/popup_messages/popup_5.jpg'
import popup_6 from 'src/images/prumdrt2020/frontend_images/popup_messages/popup_6.jpg'

export default ({ data }) => {
  const home_content = data.allRestApiApiHome.edges[0].node.content
  const [openPopup, setOpenPopup] = useState(false)
  const today = moment().utc()
  const day1 = moment('2020-05-18T02:00:00Z').utc()
  const day1_close_btn = moment('2020-05-18T07:00:00Z').utc()
  const day2 = moment('2020-05-19T06:00:00Z').utc()
  const day3 = moment('2020-05-20T06:00:00Z').utc()

  const setCookie = (name, value) => {
    const cookies = new Cookies()
    cookies.set(name, JSON.stringify(value))
  }

  const getCookie = name => {
    const cookies = new Cookies()
    const value = cookies.get(name)
    if (!!value && value.length > 0) {
      return value
    }
    return ''
  }

  const groupTimeLineSlider = popup_messages => {
    const sliders = popup_messages.map(value => {
      let valueTime = moment.tz(
        moment(
          value.date.replace('SGT', '+08'),
          'DD-MM-YYYY HH:mm:ss ZZZ'
        ).format(),
        'Singapore'
      )
      valueTime.set({ hour: 12, minute: 0, second: 0 })
      return { ...value, ...{ timeCanShowFormat: valueTime.format() } }
    })

    const groupSliders = _.groupBy(sliders, value => value.timeCanShowFormat)
    return _.map(groupSliders, group => {
      let firstValue = group[0]
      return {
        sliders: group,
        popup_code: firstValue.popup_code,
        date: firstValue.date,
        timeCanShowFormat: firstValue.timeCanShowFormat
      }
    })
  }

  const popup_messages_data = [
    {
      id: 201,
      image: popup_3,
      date: '18-05-2020 00:00:00 SGT',
      url_internal: 'http://pruevent-asia.com/prumdrt2020/#agenda',
      popup_code: 'popup_3_en'
    },
    {
      id: 202,
      image: popup_4,
      date: '19-05-2020 00:00:00 SGT',
      url_internal: 'http://pruevent-asia.com/prumdrt2020/#agenda',
      popup_code: 'popup_4_en'
    },
    {
      id: 203,
      image: popup_5,
      date: '20-05-2020 00:00:00 SGT',
      url_internal: 'http://pruevent-asia.com/prumdrt2020/#agenda',
      popup_code: 'popup_5_en'
    },
    {
      id: 204,
      image: popup_6,
      date: '28-05-2020 00:00:00 SGT',
      url_internal: '',
      popup_code: 'popup_6_en'
    }
  ]

  const currentCookieItem = getCookie('_banner_id')
  const timeLineSlider = groupTimeLineSlider(popup_messages_data)

  useEffect(() => {
    const getCurrentItem = todayImagePopup()
    if (getCurrentItem === undefined || getCurrentItem == null) {
      setOpenPopup(false)
    } else if (!!currentCookieItem && currentCookieItem.length > 0) {
      if (getCurrentItem.popup_code === currentCookieItem) {
        setOpenPopup(false)
      } else {
        setOpenPopup(true)
      }
    } else {
      setOpenPopup(true)
    }
    return () => {
      // Clean up the subscription
    }
  }, [])

  const checkDayShowTitle = () => {
    if (today.isBetween(day1, day2)) {
      return home_content.video_section_day_1_title
    } else if (today.isBetween(day2, day3)) {
      return home_content.video_section_day_2_title
    } else if (today.isSame(day3) || today.isAfter(day3)) {
      return home_content.video_section_day_3_title
    } else {
      return ''
    }
  }

  const renderWelcomeSection = () => {
    return (
      <div className="welcome-section">
        <div className="welcome-content">
          <p className="welcome-to">{home_content.welcome_to}</p>
          <p className="prudential-mdrt-text">
            {home_content.prudential_annual_meeting}
          </p>
          <p className="virtual-event-text">{home_content.virtual_event}</p>
          <p className="welcome-introduction">
            {parse(home_content.welcome_description)}
          </p>
        </div>
        <RedLine
          containerStyles={{ justifyContent: 'flex-start', height: 12.5 }}
        />
      </div>
    )
  }

  const renderAgendaSection = () => (
    <div
      className="agenda-section"
      id="agenda"
      // style={{ backgroundImage: `url(${home_content.agenda_background})` }}
    >
      <RedLine
        containerStyles={{ justifyContent: 'flex-start', height: 12.5 }}
        styles={{ mixBlendMode: 'multiply' }}
      />
      <RedLine
        containerStyles={{
          justifyContent: 'flex-start',
          height: 8,
          marginTop: 8
        }}
        styles={{ width: '30%' }}
      />
      <div className="agenda-content">
        <SectionTitle title={home_content.agenda}></SectionTitle>

        <div className="agenda-description">
          <AgendaLineItem
            programmes={home_content.agenda_sessions}
            sessions_text={home_content.sessions}
            bg_agenda_item={home_content.new_agenda_background}
          />
        </div>
      </div>
      <RedLine
        containerStyles={{
          height: 8,
          marginTop: 8,
          position: 'absolute',
          bottom: 8,
          right: 0
        }}
        styles={{ width: '30%' }}
      />
    </div>
  )

  const onClickClose = () => {
    setOpenPopup(false)
    //document.querySelector("body").classList.remove("overflow-hidden")
    let getCurrentItem = todayImagePopup()
    if (!!getCurrentItem) {
      setCookie('_banner_id', getCurrentItem.popup_code)
    }
  }
  const todayImagePopup = () => {
    const currentTimeSg = moment.tz(moment().format(), 'Singapore')
    let activeSlider = undefined
    for (let i = 0; i < timeLineSlider.length; i++) {
      let currentSlider = timeLineSlider[i]
      let nextSlider = timeLineSlider[i + 1]
      let currentSliderTime = moment.tz(
        currentSlider.timeCanShowFormat,
        'Singapore'
      )
      if (!!nextSlider) {
        let nextSliderTime = moment.tz(
          nextSlider.timeCanShowFormat,
          'Singapore'
        )
        if (
          currentTimeSg.isSame(currentSliderTime) ||
          currentTimeSg.isSame(nextSliderTime) ||
          (currentTimeSg.isAfter(currentSliderTime) &&
            currentTimeSg.isBefore(nextSliderTime))
        ) {
          activeSlider = currentSlider
          break
        }
      } else {
        if (
          currentTimeSg.isSame(currentSliderTime) ||
          currentTimeSg.isAfter(currentSliderTime)
        ) {
          activeSlider = currentSlider
        }
      }
    }

    return activeSlider
  }

  const renderSliderPopup = () => {
    const getCurrentItem = todayImagePopup()
    if (!!getCurrentItem) {
      if (!!currentCookieItem && currentCookieItem.length > 0) {
        if (getCurrentItem.popup_code === currentCookieItem) {
          return <></>
        }
      }
      return (
        <Popup
          open={openPopup}
          closeOnDocumentClick
          onClose={() => onClickClose()}
          contentStyle={{
            width: '95%',
            maxWidth: 1024
          }}
          className="home-popup sliders-popup"
          modal
          lockScroll={true}
          repositionOnResize={true}
        >
          <div className="slider-container">
            <div className="header-popup">
              <img
                src={close_btn_white}
                className="close-btn close-slider"
                onClick={() => onClickClose()}
              />
            </div>
            <div className="content">
              <SliderForPopup
                activeItem={getCurrentItem.sliders}
                customClass="slider-popup"
              />
            </div>
          </div>
        </Popup>
      )
    } else return <></>
  }

  return (
    <Layout locale={'en'}>
      <SEO title="Home" />
      <div className="home-banner-wrapper">
        <Banner banners={home_content.home_banners} customClass="home-banner" />
        {/* <img src={home_content.home_banner} alt="" /> */}

        <div className="blend-line"></div>
      </div>
      {renderWelcomeSection()}
      {renderAgendaSection()}
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiHome(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          content {
            welcome_to
            prudential_annual_meeting
            virtual_event
            welcome_description
            register_now
            contact
            contact_content
            home_banner
            home_banners
            sessions
            contact_background
            new_agenda_background
            agenda
            agenda_sessions {
              agenda_date
              agenda_time
              sessions {
                topic
              }
            }
            sessions
            agenda_background
            agenda_description
            popup_messages {
              id
              image
              date
              url_internal
              popup_code
            }
            video_section_day_1_title
            video_section_day_2_title
            video_section_day_3_title
            video_section_content
            video_section_image
          }
        }
      }
    }
  }
`
